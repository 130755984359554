// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { LineChartOutlined, IdcardOutlined, DatabaseOutlined, DashboardOutlined, SettingOutlined, RocketOutlined } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  LineChartOutlined,
  IdcardOutlined,
  DatabaseOutlined,
  DashboardOutlined,
  SettingOutlined,
  RocketOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const widget: NavItemType = {
  id: 'group-widget',
  // title: <FormattedMessage id="widgets" />,
  type: 'group',
  children: [
    {
      id: 'Users',
      title: <FormattedMessage id="Users" />,
      type: 'item',
      url: '/users',
      icon: icons.DashboardOutlined
    },
    {
      id: 'Events',
      title: <FormattedMessage id="Events" />,
      type: 'item',
      url: '/events',
      icon: icons.DatabaseOutlined
    },
    // {
    //   id: 'chart',
    //   title: <FormattedMessage id="chart" />,
    //   type: 'item',
    //   url: 'charts/apexchart',
    //   icon: icons.LineChartOutlined
    // },
    {
      id: 'configuration',
      title: <FormattedMessage id="configuration" />,
      type: 'item',
      url: '/configuration',
      icon: icons.SettingOutlined
    },
    // {
    //   id: 'Icon configuration',
    //   title: <FormattedMessage id="Icon configuration" />,
    //   type: 'item',
    //   url: 'components-overview/autocomplete',
    //   icon: icons.RocketOutlined
    // }
    
  ]
};

export default widget;
