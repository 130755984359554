import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
const Clock = () => {
    let state:any = { date: new Date() }

    const [currentTime, setCurrentTime] = useState<any | undefined>({ date: new Date() });

    useEffect(() => {
        var timerID = setInterval( () => tick(), 1000 );
  return function cleanup() {
      clearInterval(timerID);
    };
      }, []);
        
        
    function tick() {
        // this.state.date = new Date(); - wrong way
        setCurrentTime({date: new Date()});
    }

    let date: string = currentTime.date.getDate()+'/'+(currentTime.date.getMonth()+1)+'/'+currentTime.date.getFullYear();
        
    return (
        <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
            <Typography variant="h5">Date & Time : {date}, {currentTime.date.toLocaleTimeString()}</Typography>

            {/* <h2>Current Time: {currentTime.date.toLocaleTimeString()}</h2> */}
            </Box>
            );
};

export default Clock