import { useEffect, useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import _ from 'lodash';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, FormikValues } from 'formik';

// project imports
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import { openSnackbar } from 'store/reducers/snackbar';

// assets
import { CameraOutlined, DeleteFilled, DeleteTwoTone } from '@ant-design/icons';
import {  DateTimePicker } from '@mui/x-date-pickers';
import { AxiosLocal } from 'utils/axiosUtils';


const avatarImage = require.context('assets/images/users', true);

// constant
const getInitialValues = (customer: FormikValues | null) => {
  const newCustomer = {
    first_name: localStorage.getItem('username'),
    last_name: localStorage.getItem('last_name'),
    phone: localStorage.getItem('phone'),
    country:localStorage.getItem('country'),
    user_type:localStorage.getItem('user_type'),
    is_active:String(localStorage.getItem('is_active')) == "true"
   
  };

  
  return newCustomer;
};


const allStatus = ['Admin', 'Moderator', 'Primary', 'Secondary', 'Viewer', 'Guest'];

const allCountries = ['India', 'Afghanistan', 'Bangladesh', 'Bahrain', '	Japan', 'Italy', 'Maldives', 'Norway', 'Portugal'];

// ==============================|| CUSTOMER ADD / EDIT / DELETE ||============================== //

export interface Props {
  customer?: any;
  onCancel: () => void;
  userdata: Array<string | boolean>;
}

const EditProfile = ({ customer, onCancel, userdata }: Props) => {
  

  // const [value, setValue] = useState<Date | null>(new Date('2014-08-18T21:11:54'));

  // const handleChange = (newValue: Date | null) => {
  //   setValue(newValue);
  // };
  const theme = useTheme();
  const dispatch = useDispatch();
  const isCreating = !customer;

  // const [selectedImage, setSelectedImage] = useState<File | undefined>(undefined);
  // const [avatar, setAvatar] = useState<string | undefined>(
  //   avatarImage(`./avatar-${isCreating && !customer?.avatar ? 1 : customer.avatar}.png`).default
  // );

  // useEffect(() => {
  //   if (selectedImage) {
  //     setAvatar(URL.createObjectURL(selectedImage));
  //   }
  // }, [selectedImage]);

  const CustomerSchema = Yup.object().shape({
    // first_name: Yup.string().max(255).required('First name is required'),
    // last_name: Yup.string().max(255).required('Last name is required'),
    // phone: Yup.string().max(255).required('Phone is required'),
    // email: Yup.string().max(255).required('Email is required').email('Must be a valid email'),
    // password: Yup.string().max(255).required('Password is required'),
    // country: Yup.string().max(255).required('Country is required'),
    // user_type: Yup.string().max(255).required('User Type is required'),
  });

  const deleteHandler = () => {
    // dispatch(deleteCustomer(customer?.id)); - delete
    dispatch(
      openSnackbar({
        open: true,
        message: 'Customer deleted successfully.',
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    onCancel();
  };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    onSubmit: (values, { setSubmitting }) => {
      try {
        // const newCustomer = {
          
        //   first_name: values.first_name,
        //   last_name: values.last_name,
        //   phone: values.phone,
        //   email: values.email,
        //   password: values.password,
        //   country: values.country,
        //   user_type: values.user_type,
        // };
      
     
        
        
        localStorage.getItem('user_type')
        console.log(values,"uservalues");
        AxiosLocal.post(`user/edit/${localStorage.getItem('id')}/`,{...values,})
        .then(function (response: { data: any; }) {
              console.log(response.data,"localStorage");
      if(response.data.status == 'Success'){
      localStorage.setItem('username', response.data.name);
      localStorage.setItem('last_name', response.data.last_name);
      if(response.data.user_type=='V'){
      localStorage.setItem('user_type', 'Viewer');
        
      }else if(response.data.user_type=='G'){
        localStorage.setItem('user_type', 'Guest');
          
        }else if(response.data.user_type=='A'){
          localStorage.setItem('user_type', 'Admin');
            
        }else if(response.data.user_type=='M'){
          localStorage.setItem('user_type', 'Moderator');
            
          }else if(response.data.user_type=='P'){
            localStorage.setItem('user_type', 'Primary');
              
            }else if(response.data.user_type=='S'){
              localStorage.setItem('user_type', 'Secondary');
                
              }else{
      localStorage.setItem('user_type', 'Viewer');

              }
      localStorage.setItem('phone', response.data.phone);
      localStorage.setItem('country', response.data.country);
      localStorage.setItem('is_active', response.data.is_active);

            }

        })
        .catch(function (error: any) {
          console.log(error);
        });
        if (customer) {
          // dispatch(updateCustomer(customer.id, newCustomer)); - update
          dispatch(
            openSnackbar({
              open: true,
              message: 'Customer update successfully.',
              variant: 'alert',
              alert: {
                color: 'success'
              },
              close: false
            })
          );
        } else {
          // dispatch(createCustomer(newCustomer)); - add
          dispatch(
            openSnackbar({
              open: true,
              message: 'User Updated successfully.',
              variant: 'alert',
              alert: {
                color: 'success'
              },
              close: false
            })
          );
        }

        setSubmitting(false);
        onCancel();
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle>{customer ? 'Edit User' : 'Add User'}</DialogTitle>
          <Divider />
          <DialogContent sx={{ pt: 2.5, pr: 2.5, pb: 2.5, pl: 7}}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={3}>
              <Grid container spacing={4}>
              <Grid item xs={12}>

                <Stack direction="row" justifyContent="center" sx={{ mt: 1 }}>
                  <FormLabel
                    htmlFor="change-avtar"
                    sx={{
                      position: 'relative',
                      borderRadius: '50%',
                      overflow: 'hidden',
                      '&:hover .MuiBox-root': { opacity: 1 },
                      cursor: 'pointer'
                    }}
                  >
                    <Avatar alt="Avatar 1"
                    //  src={avatar} 
                     sx={{ width: 72, height: 72, border: '1px dashed' }} />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .75)' : 'rgba(0,0,0,.65)',
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Stack spacing={0.5} alignItems="center">
                        <CameraOutlined style={{ color: theme.palette.secondary.lighter, fontSize: '2rem' }} />
                        <Typography sx={{ color: 'secondary.lighter' }}>Upload</Typography>
                      </Stack>
                    </Box>
                  </FormLabel>
                  <TextField
                    type="file"
                    id="change-avtar"
                    label="Outlined"
                    variant="outlined"
                    sx={{ display: 'none' }}
                    // onChange={(e: ChangeEvent<HTMLInputElement>) => setSelectedImage(e.target.files?.[0])}
                  />
                </Stack>
                </Grid>

                <Grid item xs={12}>

                <Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
                <InputLabel htmlFor="customer-name">Organization List</InputLabel>
                   
                </Stack>
                <div style={{border:'1px solid #80808045', padding:'0px 10px 10px 5px', borderRadius:'4px'}}>
                <Stack style={{display:'flex',justifyContent:'space-between',alignItems:'center',flexDirection:'row'}} sx={{ mt: 2, ml: 1 }}>
                <InputLabel style={{color:'black'}}>MeetMo</InputLabel>
                  <DeleteTwoTone twoToneColor={theme.palette.error.main} />
                   
                </Stack>

                <Stack style={{display:'flex',justifyContent:'space-between',alignItems:'center',flexDirection:'row'}} sx={{ mt: 2, ml: 1 }}>
                <InputLabel style={{color:'black'}}>Event Fox</InputLabel>
                  <DeleteTwoTone twoToneColor={theme.palette.error.main} />
                   
                </Stack>
                </div>

                </Grid>

                </Grid>
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="customer-name">First name</InputLabel>
                      <TextField
                        fullWidth
                        id="customer-name"
                        placeholder="Enter First Name"
                        {...getFieldProps('first_name')}
                        error={Boolean(touched.first_name && errors.first_name)}
                        helperText={touched.first_name && errors.first_name}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={6}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="customer-name">Last name</InputLabel>
                      <TextField
                        fullWidth
                        id="customer-name"
                        placeholder="Enter Last Name"
                        {...getFieldProps('last_name')}
                        error={Boolean(touched.last_name && errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={6}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="phone">Phone</InputLabel>
                      <TextField
                        fullWidth
                        id="phone"
                        placeholder="Enter Phone"
                        {...getFieldProps('phone')}
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={touched.phone && errors.phone}
                      />
                    </Stack>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="customer-email">Email</InputLabel>
                      <TextField
                        fullWidth
                        id="customer-email"
                        placeholder="Enter email"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Stack>
                  </Grid> */}
                  {/* <Grid item xs={6}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="password">Password</InputLabel>
                      <TextField
                        fullWidth
                        id="password"
                        placeholder="Enter Password"
                        {...getFieldProps('password')}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />
                    </Stack>
                  </Grid> */}
                  <Grid item xs={6}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="customer-country">Choose Country</InputLabel>
                      <FormControl fullWidth>
                        <Select
                          id="column-hiding"
                          displayEmpty
                          {...getFieldProps('country')}
                          onChange={(event: SelectChangeEvent<string>) => setFieldValue('country', event.target.value as string)}
                          input={<OutlinedInput id="select-column-hiding" placeholder="Sort by" />}
                          renderValue={(selected) => {
                            if (!selected) {
                              return <Typography variant="subtitle1">Select Country</Typography>;
                            }

                            return <Typography variant="subtitle2">{selected}</Typography>;
                          }}
                        >
                          {allCountries.map((column: any) => (
                            <MenuItem key={column} value={column}>
                              <ListItemText primary={column} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {touched.country && errors.country && (
                        <FormHelperText error id="standard-weight-helper-text-email-login" sx={{ pl: 1.75 }}>
                          {errors.country}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="customer-role">User Role</InputLabel>
                      <FormControl fullWidth>
                        <Select
                          id="column-hiding"
                          displayEmpty
                          {...getFieldProps('user_type')}
                          onChange={(event: SelectChangeEvent<string>) => setFieldValue('user_type', event.target.value as string)}
                          input={<OutlinedInput id="select-column-hiding" placeholder="Sort by" />}
                          renderValue={(selected) => {
                            if (!selected) {
                              return <Typography variant="subtitle1">Select Status</Typography>;
                            }

                            return <Typography variant="subtitle2">{selected}</Typography>;
                          }}
                        >
                          {allStatus.map((column: any) => (
                            <MenuItem key={column} value={column}>
                              <ListItemText primary={column} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {touched.user_type && errors.user_type && (
                        <FormHelperText error id="standard-weight-helper-text-email-login" sx={{ pl: 1.75 }}>
                          {errors.user_type}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Stack spacing={1.25}>
                      <InputLabel htmlFor="customer-location">Location</InputLabel>
                      <TextField
                        fullWidth
                        id="customer-location"
                        multiline
                        rows={2}
                        placeholder="Enter Location"
                        {...getFieldProps('location')}
                        error={Boolean(touched.location && errors.location)}
                        helperText={touched.location && errors.location}
                      />
                    </Stack>
                  </Grid> */}
                 
                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                      <Stack spacing={0.5}>
                        <Typography variant="subtitle1">Is Active</Typography>
                        <Typography variant="caption" color="textSecondary">
                          ON/OFF
                        </Typography>
                      </Stack>
                      <Switch checked={formik.values.is_active}  onChange={() =>
                setFieldValue(
                'is_active',
                !formik.values.is_active,
                )
            }/>

                    </Stack>
                    <Divider sx={{ my: 2 }} />
                    {/* <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
                      <Stack spacing={0.5}>
                        <Typography variant="subtitle1">Available to hire</Typography>
                        <Typography variant="caption" color="textSecondary">
                          Toggling this will let your teammates know that you are available for acquiring new projects
                        </Typography>
                      </Stack>
                      <FormControlLabel control={<Switch sx={{ mt: 0 }} />} label="" labelPlacement="start" />
                    </Stack> */}
                  </Grid>
                  
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ p: 2.5 }}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                {!isCreating && (
                  <Tooltip title="Delete Customer" placement="top">
                    <IconButton onClick={deleteHandler} size="large" color="error">
                      <DeleteFilled />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Button color="error" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" disabled={isSubmitting}>
                    {customer ? 'Edit' : 'Update'}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      </LocalizationProvider>
    </FormikProvider>
  );
};

export default EditProfile;
