import { Link } from 'react-router-dom';
import { To } from 'history';

// material-ui
import { ButtonBase } from '@mui/material';
import { SxProps } from '@mui/system';

// project import
import NewLogo from 'assets/images/landing/logo.png';
// import Logo from './LogoMain';
// import LogoIcon from './LogoIcon';
import config from 'config';

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  reverse?: boolean;
  isIcon?: boolean;
  sx?: SxProps;
  to?: To;
}

const LogoSection = ({ reverse, isIcon, sx, to }: Props) => (
  <ButtonBase disableRipple component={Link} to={!to ? config.defaultPath : to} sx={sx}>
    {isIcon ? <img style={{width: '100%'}} src={NewLogo} alt="" /> : <img style={{width: '60%'}} src={NewLogo} alt="" />}
  </ButtonBase>
);

export default LogoSection;
