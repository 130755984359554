import { Button, Typography } from "@mui/material";
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ListenPermission from "./listen";


export default function ListenPermissionPermissionPage() {
//   const { id } = useParams();
const { id }  = useParams<{id?: any}>();



  return (
    <div style={{
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
   

    }} className="eventWaiting">
    
    {/* round image on center */}
  
    <div style={{
        position: 'absolute',
        backgroundColor:"#000",
        color:"#fff",
        height:"100vh",
        width:"100%",
    }}>


<div style={{display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '79px',
    
    }}>

<ListenPermission id={id}/>

    </div>

        </div>

     

    </div>
  )
}








