import { useState } from 'react';
import {
    FormControl,
    Grid,
    InputLabel,
    Stack,
    MenuItem,
    Select,
    Box
  } from '@mui/material';
import { TimeZoneList, timezones } from 'data/timezone';
// import timezones from 'timezones-list';

const TimeZoneSelection = () =>{

    const [currentTimeZone, setCurrentTimeZone] = useState<any | undefined>(Intl.DateTimeFormat().resolvedOptions().timeZone);

    const handleChange = (event:any) => {
        setCurrentTimeZone(event.target.value);
    };

    console.log(currentTimeZone,"timezonestimezones");
    

    return (
        <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
        <Grid item xs={12} md={4}>
            <Stack spacing={1.25}>
                {/* <InputLabel id="demo-simple-select-label">Timezone</InputLabel> */}
                <FormControl fullWidth>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={currentTimeZone} displayEmpty onChange={handleChange} inputProps={{ 'aria-label': 'Without label' }}>
                        <MenuItem value="" sx={{ color: 'text.secondary' }}>
                            Select Timezone
                        </MenuItem>
                        {timezones.map((timezone)=><MenuItem value={timezone}>{timezone}</MenuItem>)}
                    </Select>
                </FormControl>
            </Stack>
        </Grid>
        </Box>
    )
};

export default TimeZoneSelection