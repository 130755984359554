import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// project import
// import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import ComponentsRoutes from './ComponentsRoutes';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import AdminLogin from 'pages/auth/adminlogin';
// import HeaderPage from 'sections/landing/Header';
// import Header1 from 'layout/CommonLayout/Header1';
// import AdminLogin from 'pages/auth/adminlogin';

// render - landing page
const PagesLanding = Loadable(lazy(() => import('pages/landing')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: <AdminLogin />,
      // children: [
      //   {
      //     path: '/',
      //     element: <PagesLanding />
      //   }
      // ]
    },
    LoginRoutes,
    ComponentsRoutes,
    MainRoutes
  ]);
}
