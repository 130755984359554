// import React from 'react';
// import { useNavigate } from 'react-router-dom';

// // material-ui
// import {
//   Button,
//   Checkbox,
//   Divider,
//   FormControlLabel,
//   FormHelperText,
//   Grid,
//   Link,
//   InputAdornment,
//   InputLabel,
//   OutlinedInput,
//   Stack,
//   Typography
// } from '@mui/material';

// // third party
// import * as Yup from 'yup';
// import { Formik } from 'formik';

// // project import
// import useAuth from 'hooks/useAuth';
// import useScriptRef from 'hooks/useScriptRef';
// // import FirebaseSocial from './FirebaseSocial';
// import IconButton from 'components/@extended/IconButton';
// import AnimateButton from 'components/@extended/AnimateButton';

// // assets
// import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
// import { useFormik } from 'formik';
// import { AxiosLocal } from 'utils/axiosUtils';



// // ============================|| FIREBASE - LOGIN ||============================ //

// const AdminLog = () => {
//   const navigate = useNavigate();
//   const [checked, setChecked] = React.useState(false);
//   const [capsWarning, setCapsWarning] = React.useState(false);

//   const { isLoggedIn, firebaseEmailPasswordSignIn } = useAuth();
//   const scriptedRef = useScriptRef();

//   const [showPassword, setShowPassword] = React.useState(false);
//   const handleClickShowPassword = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleMouseDownPassword = (event: React.SyntheticEvent) => {
//     event.preventDefault();
//   };

//   const onKeyDown = (keyEvent: any) => {
//     if (keyEvent.getModifierState('CapsLock')) {
//       setCapsWarning(true);
//     } else {
//       setCapsWarning(false);
//     }
//   };
 
//   const formik = useFormik({
//     initialValues: {
//       password:'',
//       username:'',
    
//     },
//     validationSchema: Yup.object({
//       password: Yup.string()
//         .max(15, 'Must be 15 characters or less')
//         .required('Required'),

//       username: Yup.string().email('Must be a valid email').required('Required'),
     
//     }),
//     onSubmit: values => {
//       alert(JSON.stringify(values, null, 2));
//     },
//   });
//   console.log(formik.values,"formik.values");


//   const loginSubmit = () =>{

//       AxiosLocal.post('user/login/', {
//         username:formik.values.username,
//         password:formik.values.password
//       })
//       .then(function (response: { data: { accessToken: string; name: string; last_name: string; id: string; user_type: string; }; }) {
//         console.log(response,"responselogin");
//         // const userInformation = JSON.parse(response.data.user_info)
//         localStorage.setItem('accessToken', response.data.accessToken);
//         localStorage.setItem('refreshToken', response.data.accessToken);
//         localStorage.setItem('username', response.data.name);
//         localStorage.setItem('last_name', response.data.last_name);
//         localStorage.setItem('user_type', response.data.user_type);
  
       

//         // localStorage.setItem('userInformation', JSON.parse(response.data.user_info));

//         window.location.href = "/users"
        
        
//         // user set on redux
//       })
//       .catch(function (error: { response: { data: { status: string; }; }; }) {
//         console.log(error);
//         // if(error.response.data.status == 'Error'){
//         //   setOpenToast(true)
//         //   setLoggedin('')
//         // }
//       });
      
//     };


//   return (
//     <>
//       {/* <Formik
//         initialValues={{
//           email: 'info@codedthemes.com',
//           password: '123456',
//           submit: null
//         }}
//         validationSchema={Yup.object().shape({
//           email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
//           password: Yup.string().max(255).required('Password is required')
//         })}
//         onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
//           try {
//             await firebaseEmailPasswordSignIn(values.email, values.password).then(
//               () => {
//                 // WARNING: do not set any formik state here as formik might be already destroyed here. You may get following error by doing so.
//                 // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
//                 // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
//                 // github issue: https://github.com/formium/formik/issues/2430
//               },
//               (err: any) => {
//                 setStatus({ success: false });
//                 setErrors({ submit: err.message });
//                 setSubmitting(false);
//               }
//             );
//           } catch (err: any) {
//             console.error(err);
//             if (scriptedRef.current) {
//               setStatus({ success: false });
//               setErrors({ submit: err.message });
//               setSubmitting(false);
//             }
//           }
//         }}
//       >
//         {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => ( */}
//           <form noValidate onSubmit={formik.handleSubmit}>
//             <Grid container spacing={3}>
//               <Grid item xs={12}>
//                 <Stack spacing={1}>
//                   <InputLabel htmlFor="email-login">User Name</InputLabel>
//                   <OutlinedInput
//                     id="email-login"
//                     type="text"
//                     {...formik.getFieldProps('username')} 
//                     onChange={(event:any) =>formik.setFieldValue('username', event.target.value)}

//                     name="email"
                    
//                     placeholder="Enter email address"
//                     fullWidth
                   
//                   />
//                      {formik.touched.username && formik.errors.username ? (
//          <div style={{color:'#ae0000',fontSize:'small'}}>{formik.errors.username}</div>
        
//        ) : null}

//                   {/* {touched.email && errors.email && (
//                     <FormHelperText error id="standard-weight-helper-text-email-login">
//                       {errors.email}
//                     </FormHelperText>
//                   )} */}
//                 </Stack>
//               </Grid>
//               <Grid item xs={12}>
//                 <Stack spacing={1}>
//                   <InputLabel htmlFor="password-login">Password</InputLabel>
//                   <OutlinedInput
//                     fullWidth
//                     color={capsWarning ? 'warning' : 'primary'}
                    
//                     id="-password-login"
//                     type={showPassword ? 'text' : 'password'}
//                     // sx={{
//                     //   '& .MuiOutlinedInput-notchedOutline':{
                        
//                     //     border:formik.touched.password && formik.errors.password ?'1px solid':'2px solid',
//                     //     borderColor:formik.touched.password && formik.errors.password ?'#ae0000':'grey',
//                     //     borderRadius:'4px'

//                     //   },
//                     //   '&:hover .MuiOutlinedInput-notchedOutline':{
                        
//                     //     border:formik.touched.password && formik.errors.password ?'1px solid':'2px solid',

//                     //     borderColor:formik.touched.password && formik.errors.password ?'#ae0000':'grey',

//                     //     borderRadius:'4px'
//                     //   },
//                     //   '& .MuiOutlinedInput-input': {
//                     //     border:formik.touched.password && formik.errors.password ?'1px solid':'2px solid',

//                     //     borderColor:formik.touched.password && formik.errors.password ?'#ae0000':'grey',
//                     //     borderRadius:'4px'

//                     //   }
//                     // }}
                    
//                     {...formik.getFieldProps('password')} 
//                     onChange={(event:any) =>formik.setFieldValue('password', event.target.value)}

//                     onKeyDown={onKeyDown}
                    
//                     endAdornment={
//                       <InputAdornment position="end">
//                         <IconButton
//                           aria-label="toggle password visibility"
//                           onClick={handleClickShowPassword}
//                           onMouseDown={handleMouseDownPassword}
//                           edge="end"
//                           color="secondary"
//                         >
//                           {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
//                         </IconButton>
//                       </InputAdornment>
//                     }
//                     placeholder="Enter password"
//                   />
//                   {capsWarning && (
//                     <Typography variant="caption" sx={{ color: 'warning.main' }} id="warning-helper-text-password-login">
//                       Caps lock on!
//                     </Typography>
//                   )}
//                            {formik.touched.password && formik.errors.password ? (
//          <div style={{color:'#ae0000',fontSize:'small'}}>{formik.errors.password}</div>
//         // <FormHelperText error id="standard-weight-helper-text-email-login">
//         //               {formik.errors.username}
//         //             </FormHelperText>
//        ) : null}
//                 </Stack>
//               </Grid>

//               {/* <Grid item xs={12} sx={{ mt: -1 }}>
//                 <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
//                   <FormControlLabel
//                     control={
//                       <Checkbox
//                         checked={checked}
//                         onChange={(event) => setChecked(event.target.checked)}
//                         name="checked"
//                         color="primary"
//                         size="small"
//                       />
//                     }
//                     label={<Typography variant="h6">Keep me sign in</Typography>}
//                   />
//                   <Link
//                     variant="h6"
//                     component={RouterLink}
//                     to={isLoggedIn ? '/auth/forgot-password' : '/forgot-password'}
//                     color="text.primary"
//                   >
//                     Forgot Password?
//                   </Link>
//                 </Stack>
//               </Grid> */}
              
//               <Grid item xs={12}>

//                 <AnimateButton>
              

//                   <Button onClick={() => loginSubmit()} fullWidth size="large" type="submit" variant="contained" color="primary">
//                   Login
//                   </Button>


//                 </AnimateButton>
              
//               </Grid>
//               {/* <Grid item xs={12}>
//                 <Divider>
//                   <Typography variant="caption"> Login with</Typography>
//                 </Divider>
//               </Grid>
//               <Grid item xs={12}>
//                 <FirebaseSocial />
//               </Grid> */}
//             </Grid>
//           </form>
//         {/* )}
//       </Formik> */}
//     </>
//   );
// };

// export default AdminLog;

import React, { forwardRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Modal,
  CardContent
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project import
import useAuth from 'hooks/useAuth';
import useScriptRef from 'hooks/useScriptRef';
// import FirebaseSocial from './FirebaseSocial';
import IconButton from 'components/@extended/IconButton';
import AnimateButton from 'components/@extended/AnimateButton';

// assets
import { EyeOutlined, EyeInvisibleOutlined, FileExcelFilled } from '@ant-design/icons';
import BasicModal from 'sections/components-overview/modal/BasicModal';
import MainCard from 'components/MainCard';
import { AxiosLocal } from 'utils/axiosUtils';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';


const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// ============================|| FIREBASE - LOGIN ||============================ //

const AdminLog = () => {
//toast
  const [opentoast, setOpentoast] = useState(false);

  const handleClickToast = () => {
    setOpentoast(true);
  };

  const handleCloseToast = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpentoast(false);
  };

  const navigate = useNavigate();
  const [checked, setChecked] = React.useState(false);
  const [capsWarning, setCapsWarning] = React.useState(false);

  const { isLoggedIn, firebaseEmailPasswordSignIn } = useAuth();
  const scriptedRef = useScriptRef();

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const onKeyDown = (keyEvent: any) => {
    if (keyEvent.getModifierState('CapsLock')) {
      setCapsWarning(true);
    } else {
      setCapsWarning(false);
    }
  };

  const [username, setusername] = useState();
  const [password, setpassword] = useState();
  const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  console.log(localStorage,"localStorage");

  const loginSubmit = () =>{
    
    AxiosLocal.post('user/login/', {
      username:username,
      password:password
    })
    .then(function (response: { data: {
      organisations_list: any; accessToken: string; refreshToken: string; name: string; last_name: string; user_type: string; phone: string; country: string; id: string; is_active: string;
}; }) {
      console.log(response, "response");
      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.accessToken);
      localStorage.setItem('username', response.data.name);
      localStorage.setItem('last_name', response.data.last_name);
      if(response.data.user_type=='V'){
      localStorage.setItem('user_type', 'Viewer');
        
      }else if(response.data.user_type=='G'){
        localStorage.setItem('user_type', 'Guest');
          
        }else if(response.data.user_type=='A'){
          localStorage.setItem('user_type', 'Admin');
            
        }else if(response.data.user_type=='M'){
          localStorage.setItem('user_type', 'Moderator');
            
          }else if(response.data.user_type=='P'){
            localStorage.setItem('user_type', 'Primary');
              
            }else if(response.data.user_type=='S'){
              localStorage.setItem('user_type', 'Secondary');
                
              }else{
      localStorage.setItem('user_type', 'Viewer');

              }
      localStorage.setItem('phone', response.data.phone);
      localStorage.setItem('country', response.data.country);
      localStorage.setItem('id', response.data.id);
      localStorage.setItem('is_active', response.data.is_active);

    
    const organization_list: string[] = [];


    {
      response.data.organisations_list.map((item: { company_name: any; }) => {
         

        organization_list.push((item.company_name))
            
        })

    
    }

    localStorage.setItem('organisations', JSON.stringify(organization_list));

    

     
    

      window.location.href = "/users"
    })
    .catch(function (error: { response: { data: { status: string; }; }; }) {
      console.log(error.response.data, "response");
      if(error.response.data.status==='Error'){
        handleClickToast()
      }
    });
    
  };

 
 


  return (
    <>
      {/* <Formik
        initialValues={{
          email: 'info@codedthemes.com',
          password: '123456',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
          password: Yup.string().max(255).required('Password is required')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await firebaseEmailPasswordSignIn(values.email, values.password).then(
              () => {
                // WARNING: do not set any formik state here as formik might be already destroyed here. You may get following error by doing so.
                // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
                // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
                // github issue: https://github.com/formium/formik/issues/2430
              },
              (err: any) => {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            );
          } catch (err: any) {
            console.error(err);
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      > */}
        {/* {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => ( */}
          <form noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="email-login">User Name</InputLabel>
                  <OutlinedInput
                    id="email-login"
                    type="text"
                    required={true}
                    // value={values.email}
                    name="email"
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                    placeholder="Enter email address"
                    fullWidth
                    onChange={(event:any)=>{
                      setusername(event.target.value)
                    }}
                    // error={Boolean(touched.email && errors.email)}
                  />
                  {/* {touched.email && errors.email && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.email}
                    </FormHelperText>
                  )} */}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="password-login">Password</InputLabel>
                  <OutlinedInput
                    fullWidth
                    color={capsWarning ? 'warning' : 'primary'}
                    // error={Boolean(touched.password && errors.password)}
                    id="-password-login"
                    type={showPassword ? 'text' : 'password'}
                    // value={values.password}
                    name="password"
                    // onBlur={(event: React.FocusEvent<any, Element>) => {
                    //   setCapsWarning(false);
                    //   handleBlur(event);
                    // }}
                    onChange={(event:any)=>{
                      setpassword(event.target.value)
                    }}
                    onKeyDown={onKeyDown}
                    // onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          color="secondary"
                        >
                          {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Enter password"
                  />
                  {/* {capsWarning && (
                    <Typography variant="caption" sx={{ color: 'warning.main' }} id="warning-helper-text-password-login">
                      Caps lock on!
                    </Typography>
                  )} */}
                  {/* {touched.password && errors.password && (
                    <FormHelperText error id="standard-weight-helper-text-password-login">
                      {errors.password}
                    </FormHelperText>
                  )} */}
                </Stack>
              </Grid>

              {/* <Grid item xs={12} sx={{ mt: -1 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={(event) => setChecked(event.target.checked)}
                        name="checked"
                        color="primary"
                        size="small"
                      />
                    }
                    label={<Typography variant="h6">Keep me sign in</Typography>}
                  />
                  <Link
                    variant="h6"
                    component={RouterLink}
                    to={isLoggedIn ? '/auth/forgot-password' : '/forgot-password'}
                    color="text.primary"
                  >
                    Forgot Password?
                  </Link>
                </Stack>
              </Grid> */}
              {/* {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Grid>
              )} */}
              <Grid item xs={12}>

                <AnimateButton>
              

                  <Button 
                  // onClick={() => navigate('dashboard')} 
                  onClick={() => {
                    console.log("loginSubmit",username,password)
                    loginSubmit()
                  }}
                  fullWidth size="large" type="button" variant="contained" color="primary">
                  Login
                  </Button>


                </AnimateButton>
              
              </Grid>
              {/* <Grid item xs={12}>
                <Divider>
                  <Typography variant="caption"> Login with</Typography>
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <FirebaseSocial />
              </Grid> */}
            </Grid>
          </form>
        {/* )} */}
      {/* </Formik> */}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <MainCard title="" modal darkTitle content={false} >
          <CardContent style={{padding:'60px'}}>
            <Typography id="modal-modal-description" style={{fontWeight:'700'}}>Email or Password is Wrong. Please Try Again</Typography>
          </CardContent>
          <Divider />
          <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ px: 2.5, py: 2 }}>
            <Button color="error" size="small" onClick={handleClose}>
              Cancel
            </Button>
            {/* <Button variant="contained" size="small">
              Submit
            </Button> */}
          </Stack>
        </MainCard>
      </Modal>
      <Snackbar open={opentoast} autoHideDuration={3000} onClose={handleCloseToast} anchorOrigin={{vertical:'bottom', horizontal:'right'}}>
        <Alert onClose={handleCloseToast} severity="info" sx={{ width: '100%' }}>
        Email or Password Incorrect. Try again.
        </Alert>
      </Snackbar>
    </>
  );
};

export default AdminLog;
