import { Link } from 'react-router-dom';

// material-ui
import { Grid, Stack, Typography } from '@mui/material';

// project import
import useAuth from 'hooks/useAuth';
import AuthWrapper from 'sections/auth/AuthWrapper';
import AdminLog from 'sections/auth/auth-forms/AdminLog';

// ================================|| LOGIN ||================================ //

const AdminLogin = () => {
  const { isLoggedIn } = useAuth();

  return (
    <div style={{backgroundColor:'#E5E5E5'}}>
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
            <Typography variant="h3">Login</Typography>
            {/* <Typography
              component={Link}
              to={isLoggedIn ? '/auth/register' : '/register'}
              variant="body1"
              sx={{ textDecoration: 'none' }}
              color="primary"
            >
              Don&apos;t have an account?
            </Typography> */}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <AdminLog />
        </Grid>
      </Grid>
    </AuthWrapper>
    </div>
  );
};

export default AdminLogin;
